<template>
  <div v-if="loading" :class="$style.logopedistDetails__loading">
    <Loading />
  </div>

  <div v-else :class="$style.logopedistDetails">
    <Title text="Детали логопеда" position="left" />
    <Tabs
      style="margin-bottom: 1.5rem"
      :tabs="tabs"
      :default-active-tab-id="activeTab"
      @input="(id) => (activeTab = id)"
    />

    <div
      v-if="Object.keys(this.logopedistDetails).length"
      :class="$style.logopedistDetails__content"
    >
      <div v-if="activeTab === 1" :class="$style.logopedistDetails__content_wrap">
        <div :class="$style.logopedistDetails__content_info">
          <p>Фамилия*</p>
          <div>
            <span>{{ setValueInField(logopedistAttributes.lastName) }}</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info">
          <p>Имя*</p>
          <div>
            <span>{{ setValueInField(logopedistAttributes.firstName) }}</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info">
          <p>Отчество</p>
          <div>
            <span>{{ setValueInField(logopedistAttributes.middleName) }}</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info">
          <p>Год рождения</p>
          <div>
            <span>{{ setValueInField(logopedistAttributes.birthday) }}</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info">
          <p>Пол</p>
          <div>
            <span>{{ setValueInField(logopedistAttributes.gender) }}</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info">
          <p>Регион*</p>
          <div>
            <span>{{ setValueInField(logopedistAttributes.region) }}</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info">
          <p>Телефон*</p>
          <div>
            <span>{{ setValueInField(logopedistDetails.phone) }}</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info">
          <p>Дополнительный телефон</p>
          <div>
            <span>{{ setValueInField(logopedistDetails.reservedPhone) }}</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info">
          <p>Почта*</p>
          <div>
            <span>{{ setValueInField(logopedistDetails.email) }}</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info">
          <p>Статус</p>
          <div>
            <span>{{
              logopedistDetails.logopedist && logopedistDetails.logopedist.isVerified
                ? 'Активен'
                : 'Не активен'
            }}</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info">
          <p>Дежурный</p>
          <div>
            <span>{{
              logopedistDetails.logopedist && logopedistDetails.logopedist.isOnDuty
                ? 'Да'
                : 'Нет'
            }}</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info">
          <p>Подтвержден методистом</p>
          <div>
            <span>{{ isVerifiedByMethodist }}</span>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 1" :class="$style.logopedistDetails__content_wrap">
        <h6 :class="$style.logopedistDetails__content_subtitle">Образование</h6>

        <div
          v-for="education in logopedistInfo.educations"
          :key="education.id"
          :class="$style.logopedistDetails__content_education"
        >
          <div :class="$style.logopedistDetails__content_info">
            <p>Вуз*</p>
            <div>
              {{ setValueInField(education.university) }}
            </div>
          </div>

          <div :class="$style.logopedistDetails__content_info">
            <p>Город*</p>
            <div>
              {{ setValueInField(education.place) }}
            </div>
          </div>

          <div :class="$style.logopedistDetails__content_info">
            <p>Специальность*</p>
            <div>
              {{ setValueInField(education.speciality) }}
            </div>
          </div>

          <div :class="$style.logopedistDetails__content_info">
            <p>Виды высшего образования</p>
            <div>
              {{ setValueInField(education.type ? education.type.name : null) }}
            </div>
          </div>

          <div :class="$style.logopedistDetails__content_info">
            <p>Педагогическое образование*</p>
            <div>
              {{ education.hasAdvancedTraining ? 'Есть' : 'Нет' }}
            </div>
          </div>

          <div :class="$style.logopedistDetails__content_info">
            <p>Год окончания*</p>
            <div>
              {{ setValueInField(education.year) }}
            </div>
          </div>
        </div>
      </div>

      <create-accrual
        v-if="activeTab === 1"
        :user-id="pedagogId"
        :is-admin="isAdmin"
      />

      <div v-if="activeTab === 3" :class="$style.logopedistDetails__content_wrap">
        <h6 :class="$style.logopedistDetails__content_subtitle">Трудовая деятельность</h6>

        <div
          v-for="workplace in logopedistInfo.workplaces"
          :key="workplace.id"
          :class="$style.logopedistDetails__content_workplace"
        >
          <div :class="$style.logopedistDetails__content_info">
            <p>Статус занятости*</p>
            <div>
              <span>{{
                workplace.isCurrent ? 'Текущее место работы' : 'Прошлое место работы'
              }}</span>
            </div>
          </div>

          <div :class="$style.logopedistDetails__content_info">
            <p>Название организации*</p>
            <div>
              <span>{{ setValueInField(workplace.organization) }}</span>
            </div>
          </div>

          <div :class="$style.logopedistDetails__content_info">
            <p>Название должности*</p>
            <div>
              <span>{{ setValueInField(workplace.position) }}</span>
            </div>
          </div>

          <div :class="$style.logopedistDetails__content_workplace_flex">
            <div
              :class="[
                $style.logopedistDetails__content_info,
                $style.logopedistDetails__content_workplace_date
              ]"
            >
              <p>Начало работы*</p>
              <div>
                <span>{{
                  setValueInField(moment(workplace.start).format('DD-MM-YYYY'))
                }}</span>
              </div>
            </div>

            <div
              v-if="workplace.end"
              :class="[
                $style.logopedistDetails__content_info,
                $style.logopedistDetails__content_workplace_date
              ]"
            >
              <p>Конец работы*</p>
              <div>
                <span>{{ setValueInField(moment(workplace.end).format('DD-MM-YYYY')) }}</span>
              </div>
            </div>
          </div>

          <div :class="$style.logopedistDetails__content_info">
            <p>ФИО непосредственного руководителя*</p>
            <div>
              <span>{{ setValueInField(workplace.chiefFullName) }}</span>
            </div>
          </div>

          <div
            v-if="workplace.dismissalReason"
            :class="$style.logopedistDetails__content_info"
          >
            <p>Причины ухода*</p>
            <div>
              <span>{{ setValueInField(workplace.dismissalReason) }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 3" :class="$style.logopedistDetails__content_flex">
        <div :class="$style.logopedistDetails__content_info" style="min-width: 400px">
          <p>Кол-во случаев частной коррекционной логопедической практики*</p>
          <div>
            <span>{{
              setValueInField(logopedistInfo.privateCorrectionalPracticesQuantity)
            }}</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info" style="min-width: 500px">
          <p>Перечень речевых нарушений с которыми вы работали*</p>
          <div v-if="speechDisordersExperiences.length">
            <span
              v-for="speechDisordersExperience in speechDisordersExperiences"
              :key="speechDisordersExperience.id"
              :class="$style.logopedistDetails__content_item"
              >{{
                speechDisordersExperience.customName
                  ? speechDisordersExperience.customName
                  : speechDisordersExperience.speechDisorder.name
              }}</span
            >
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info" style="min-width: 500px">
          <p>Перечень речевых нарушений с которыми вы предпочли бы работать*</p>
          <div v-if="speechDisordersPreferences.length">
            <span
              v-for="speechDisordersPreference in speechDisordersPreferences"
              :key="speechDisordersPreference.id"
              :class="$style.logopedistDetails__content_item"
              >{{
                speechDisordersPreference.customName
                  ? speechDisordersPreference.customName
                  : speechDisordersPreference.speechDisorder.name
              }}</span
            >
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info" style="min-width: 500px">
          <p>Диагностические технологии, которые вы наиболее часто используете в практике*</p>
          <div v-if="diagnosticTechnologiesPreferences.length">
            <span
              v-for="diagnosticTechnologiesPreference in diagnosticTechnologiesPreferences"
              :key="diagnosticTechnologiesPreference.id"
              :class="$style.logopedistDetails__content_item"
              >{{
                diagnosticTechnologiesPreference.customName
                  ? diagnosticTechnologiesPreference.customName
                  : diagnosticTechnologiesPreference.diagnosticTechnology.name
              }}</span
            >
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info">
          <p>Самый значимый результат коррекционной работы*</p>
          <div>
            <span>{{ setValueInField(logopedistInfo.significantWorkingResult) }}</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info" style="min-width: 400px">
          <p>Перечень возрастных категорий детей c которыми вы работали*</p>
          <div v-if="childrenAgeGroupsExperiences.length">
            <span
              v-for="childrenAgeGroupsExperience in childrenAgeGroupsExperiences"
              :key="childrenAgeGroupsExperience.id"
              :class="$style.logopedistDetails__content_item"
              >{{
                childrenAgeGroupsExperience.childrenAgeGroup
                  ? childrenAgeGroupsExperience.childrenAgeGroup.name
                  : 'Не заполнено'
              }}</span
            >
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info" style="min-width: 400px">
          <p>Перечень предпочитаемых возрастных категорий детей*</p>
          <div v-if="childrenAgeGroupsPreferences.length">
            <span
              v-for="childrenAgeGroupsPreference in childrenAgeGroupsPreferences"
              :key="childrenAgeGroupsPreference.id"
              :class="$style.logopedistDetails__content_item"
              >{{
                childrenAgeGroupsPreference.childrenAgeGroup
                  ? childrenAgeGroupsPreference.childrenAgeGroup.name
                  : 'Не заполнено'
              }}</span
            >
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div :class="$style.logopedistDetails__content_info" style="min-width: 70%">
          <p>Контакты коллег с коротыми вы работали, кто может вас рекомендовать*</p>
          <div v-if="recommendationContacts.length">
            <span
              v-for="recommendationContact in recommendationContacts"
              :key="recommendationContact.id"
              :class="$style.logopedistDetails__content_item"
              >{{
                `ФИО*: ${setValueInField(
                  recommendationContact.fullName
                )}; Телефон: ${setValueInField(
                  recommendationContact.phone
                )}; Email*: ${setValueInField(recommendationContact.email)} `
              }}</span
            >
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div
          :class="[
            $style.logopedistDetails__content_info,
            $style.logopedistDetails__content_info_textarea
          ]"
        >
          <p>О себе*</p>
          <div>
            <span>{{ setValueInField(logopedistInfo.personalInfo) }}</span>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 2" :class="$style.logopedistDetails__calendar">
        <div :class="$style.periods">
          <img src="../assets/icons/calendar-pin.svg" alt=" " class="pin" />

          <p :class="$style.subtitle">ГРАФИК РАБОТЫ</p>

          <div :class="$style.calendar">
            <Calendar
              :periods="periods"
              @set-date="(date) => setChoosenDate(date)"
            />
          </div>
        </div>

        <periods-list
          :choosen-date="choosenDate"
          :periods="periods"
          :all-orders="logopedistOrders.rows"
        />
        <!-- :choosen-date="choosenDate"
          :periods="periods"
          :all-orders="teacherOrders.rows" -->
      </div>

      <div
        v-if="pedagogFiles.length && activeTab === 3"
        :class="$style.logopedistDetails__files"
      >
        <div
          v-for="file in pedagogFiles"
          :key="file.id"
          :class="$style.logopedistDetails__file"
        >
          <span> {{ file.name }}</span>
          <img
            src="@/assets/icons/save.svg"
            alt="download"
            @click="downloadFileFromServer(file)"
          />
        </div>
      </div>

      <div
        v-if="activeTab === 4"
        :class="[$style.logopedistDetails__notes, $style.notes]"
      >
        <div :class="$style.notes__current">
          <p :class="$style.notes__title">
            Текущее значение
          </p>

          <custom-textarea
            ref="currentCommentTextarea"
            :class="$style.notes__comment"
            :value="currentCommentText"
            :maxlength="1024"
          />

          <div :class="$style.notes__buttons">
            <Button
              type="tertiary"
              @click="addNewComment"
            >
              Сохранить
            </Button>
            <Button
              type="red"
              @click="clearNewComment"
            >
              Очистить
            </Button>
          </div> 
        </div>

        <div :class="$style.notes__previous">
          <p :class="$style.notes__title">
            Предыдущее значение
          </p>

          <custom-table
            :columns="[
              { column: 'Дата', value: (item) => item.date || '', width: 10 },
              { column: 'Время', value: (item) => item.time || '', width: 5 },
              {
                column: 'Ответсвенный',
                value: (item) => {
                  const employe = item.employe
                  return employe ? `${employe.firstName} ${employe.lastName}` : ''
                },
                width: 15,
              },
              { column: 'Примечание', value: (item) => item.text || '', width: 20 },
            ]"
            :items="previousComment"
          />
        </div>
      </div>
    </div>
    <div v-if="isAdmin" :class="$style.logopedistDetails__form">
      <Button
        type="table"
        :class="$style.logopedistDetails__form_approve"
        @click="checkIsSelfEmployed"
      >
        Проверить статус самозанятости
      </Button>
      <div style="text-align: center; margin-bottom: 20px" v-show="showIsSelfEmployed">
        {{ isSelfEmployed ? 'Самозанят' : 'Не самозанят' }}
      </div>
    </div>

    <div
      :class="$style.logopedistDetails__form"
      v-if="roleId === employeeRoles.METHODIST_LOGOPEDIST"
    >
      <Button
        type="table"
        :class="$style.logopedistDetails__form_approve"
        @click="() => toggleIsVerifiedByMethodist(true)"
      >
        Подтвердить
      </Button>
      <Button
        type="table"
        :class="$style.logopedistDetails__form_approve"
        @click="deleteTeacher"
      >
        Удалить
      </Button>
      <Button
        type="table"
        :class="$style.logopedistDetails__form_decline"
        @click="toggleIsTextAreaShown"
      >
        Отклонить
      </Button>

      <div :class="$style.logopedistDetails__form_textarea" v-show="isTextAreaShown">
        <i
          @click="toggleIsTextAreaShown"
          :class="$style.logopedistDetails__form_textarea_close"
        >
          X
        </i>

        <button
          :class="$style.logopedistDetails__form_textarea_send"
          @click="() => toggleIsVerifiedByMethodist(false)"
        >
          Отправить
        </button>

        <textarea
          v-model="declineReason"
          placeholder="Опишите причину отказа"
          rows="15"
          cols="55"
        />
      </div>
    </div>

    <div :class="$style.logopedistDetails__form" v-else-if="isAdmin">
      <Button
        type="table"
        :class="$style.logopedistDetails__form_approve"
        @click="() => toggleIsVerifiedByAdmin(true)"
      >
        Подтвердить
      </Button>

      <Button
        type="table"
        :class="$style.logopedistDetails__form_approve"
        @click="deleteTeacher"
      >
        Удалить
      </Button>

      <Button
        type="table"
        :class="$style.logopedistDetails__form_decline"
        @click="() => toggleIsVerifiedByAdmin(false)"
      >
        Отклонить
      </Button>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/users'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios from '@/plugins/axios'
import Moment from 'moment'
import { EmployeeRoles } from '@/application/constants'
import { LessonStatus } from '@/application/constants'

import Title from '@/basic/Title'
import Button from '@/basic/Button'
import Loading from '@/basic/Loading'
import Tabs from '@/basic/Tabs'
import Calendar from '@/basic/Calendar'
import CustomTextarea from '@/basic/CustomTextarea.vue'
import CustomTable from '@/basic/CustomTable'
import { CreateAccrual } from '@/widgets/create-accrual'
import { PeriodsList } from '@/widgets/periods'

export default {
  name: 'LogopedistDetails',
  components: {
    Title,
    Button,
    Loading,
    Tabs,
    Calendar,
    CustomTextarea,
    CustomTable,
    CreateAccrual,
    PeriodsList,
  },
  data() {
    return {
      loading: false,
      minutes: null,
      isVerified: false,
      isTextAreaShown: false,
      declineReason: '',
      isSelfEmployed: false,
      showIsSelfEmployed: false,
      choosenDate: Moment().format('YYYY-MM-DD'),
      tabs: [
        {
          id: 1,
          name: 'Личные данные'
        },
        {
          id: 2,
          name: 'Календарь'
        },
        {
          id: 3,
          name: 'Доп. информация'
        },
        {
          id: 4,
          name: 'Примечание'
        }
      ],
      periods: [],
      activeTab: 1,
      employeeRoles: EmployeeRoles
    }
  },
  async created() {
    this.getLogopedistProfile()
  },
  computed: {
    ...mapGetters({
      logopedistDetails: 'logopedistDetails',
      logopedistOrders: 'logopedistOrders',
      pedagogFiles: 'pedagogFiles',
      roleId: 'userRoleId',
      isAdmin: 'isAdmin'
    }),

    currentCommentText() {
      return this.logopedistInfo.comments[0] ? this.logopedistInfo.comments[0].text : ''
    },

    previousComment() {
      return this.logopedistInfo.comments[1] ? [this.logopedistInfo.comments[1]] : []
    },

    moment() {
      return Moment
    },

    pedagogId() {
      return this.logopedistDetails?.id ?? this.$route.params.id
    },

    logopedistAttributes() {
      return this.logopedistDetails.attributes
    },

    logopedistInfo() {
      return this.logopedistDetails.logopedist
    },

    isVerifiedByMethodist() {
      return this.logopedistInfo?.isVerifiedByMethodist
        ? 'Да'
        : `Нет; Причина отказа : ${
            this.logopedistInfo.declineByMethodistReason ?? 'Не указано'
          }` ?? null
    },

    speechDisordersExperiences() {
      return this.logopedistInfo.speechDisordersExperiences
    },

    speechDisordersPreferences() {
      return this.logopedistInfo.speechDisordersPreferences
    },

    diagnosticTechnologiesPreferences() {
      return this.logopedistInfo.diagnosticTechnologiesPreferences
    },

    childrenAgeGroupsExperiences() {
      return this.logopedistInfo.childrenAgeGroupsExperiences
    },

    childrenAgeGroupsPreferences() {
      return this.logopedistInfo.childrenAgeGroupsPreferences
    },

    recommendationContacts() {
      return this.logopedistInfo.recommendationContacts
    }
  },
  methods: {
    ...mapActions(['getLogopedist', 'getLogopedistOrders', 'getPedagogFiles']),
    ...mapMutations(['setLogopedistDetails']),
    setChoosenDate(date) {
      this.choosenDate = date
    },
    async getLogopedistProfile() {
      try {
        this.loading = true
        await Promise.all([
          this.getLogopedist(this.pedagogId),
          this.getPedagogFiles(this.pedagogId),
          this.getLogopedistOrders({
            filter: {
              teacherId: this.logopedistDetails.id,
              statusId: [
                LessonStatus.NEW,
                LessonStatus.APPOINTED,
                LessonStatus.ACTIVE,
                LessonStatus.CLOSED
              ]
            }
          })
        ])
        this.periods = this.logopedistDetails.logopedist.workingPeriod
      } finally {
        this.loading = false
      }

      this.isVerified = this.logopedistDetails.logopedist.isVerified
    },
    async addNewComment() {
      try {
        const data = {
          text: this.$refs.currentCommentTextarea.$el.value,
          logopedistId: this.logopedistInfo.id,
        }
        const { status, } = await userApi.addLogopedistComment(data)

        if (status === 200) {
          await this.getLogopedistProfile()
        }
      } catch (error) {
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка добавление комментария преподавателю!'
          }
        ])
      }
    },
    async clearNewComment() {
      this.$refs.currentCommentTextarea.$el.value = ''
      await this.addNewComment()
    },
    async checkIsSelfEmployed() {
      try {
        const { data } = await userApi.checkIsSelfEmployed(this.pedagogId)

        if (data?.success) {
          this.isSelfEmployed = data.result.active

          this.toggleShowIsSelfEmployed()
        }
      } catch (error) {
        console.warn(error)
      }
    },
    toggleShowIsSelfEmployed() {
      this.showIsSelfEmployed = !this.showIsSelfEmployed
    },
    setValueInField(value) {
      return value ? value : 'Не заполнено'
    },

    toggleIsTextAreaShown() {
      this.isTextAreaShown = !this.isTextAreaShown
    },

    async toggleIsVerifiedByAdmin(value) {
      try {
        const { id } = this.$route.params
        const { data } = await axios.patch(
          `${process.env.VUE_APP_API_URL}/dashboard/logopedists/${id}`,
          {
            isApprove: value
          }
        )

        const logopedistDetails = data.result.rows[0]
        this.setLogopedistDetails(logopedistDetails)
      } catch (e) {
        alert(e)
      }
    },

    async toggleIsVerifiedByMethodist(value) {
      try {
        const { id } = this.$route.params
        if (value) {
          const { data } = await axios.patch(
            `${process.env.VUE_APP_API_URL}/dashboard/logopedists/methodist/${id}`,
            {
              isApprove: value
            }
          )
          const logopedistDetails = data.result.rows[0]
          this.setLogopedistDetails(logopedistDetails)
        } else {
          if (!this.declineReason) {
            alert('Укажите причину отказа')
          }
          const { data } = await axios.patch(
            `${process.env.VUE_APP_API_URL}/dashboard/logopedists/methodist/${id}`,
            {
              isApprove: value,
              text: this.declineReason
            }
          )
          const logopedistDetails = data.result.rows[0]
          this.setLogopedistDetails(logopedistDetails)
          this.toggleIsTextAreaShown()
        }
      } catch (e) {
        console.error(e)
        alert(e)
      }
    },
    setMinutes(value, type) {
      if (value.length === 0) {
        this[type] = null
      }
    },
    async deleteTeacher () {
      try {
        this.loading = true
        const { success } = await userApi.deleteUser({ userId: this.logopedistAttributes.userId })
        if (success) {
          await this.$router.push('/logopedists')
        } else {
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка',
              message: success
            }
          ])
        }
      } catch (error) {
        console.warn(error)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка на сервере при удалении преподавателя!'
          }
        ])
      } finally {
        this.loading = false
      }
    },
    async addMinutes() {
      try {
        this.loading = true
        const data = { userId: this.logopedistAttributes.userId }
        if (this.minutes === '0') return
        if (this.minutes) {
          data.minutes = +this.minutes
        }
        const { success } = await userApi.addMinutes(data)
        if (success) {
          this.minutes = null
          await this.getLogopedist(this.logopedistDetails.id)
        }
      } catch (error) {
        console.warn(error)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка добавление минут преподавателю на сервере!'
          }
        ])
      } finally {
        this.loading = false
      }
    },
    async downloadFileFromServer(data) {
      try {
        this.loading = true

        const resp = await userApi.downloadPedagogFile(data.id)

        if (resp?.status === 200) {
          let a = document.createElement('a')
          let file = new Blob([resp.data], { type: 'application/json' })
          a.href = URL.createObjectURL(file)
          a.download = data.name
          a.click()
        }
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.logopedistDetails {
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 90vh;
  }

  &__notes {
    margin-bottom: 20px;
  }

  &__calendar {
    display: flex;
    gap: 64px;
    height: 625px;
    overflow: auto;
    & > div {
      flex-shrink: 0;
      height: max-content;
    }
  }

  &__content {
    &_flex {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &_title {
      margin-bottom: 10px;
      h2 {
        font-size: 20px;
        font-weight: 500;
      }
    }

    &_subtitle {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 500;
    }

    &_education {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 0 0 1rem;
    }

    &_workplace {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin: 0 0 1rem;

      &_date {
        min-width: auto !important;
        width: 7rem !important;
      }

      &_flex {
        display: flex;
      }
    }

    &_wrap {
      display: flex;
      flex-wrap: wrap;
    }

    &_info {
      position: relative;
      min-width: 275px;
      width: auto;
      margin-right: 10px;
      margin-bottom: 15px;

      p {
        position: absolute;
        top: -8px;
        left: 5px;
        background: $white;
        font-size: 12px;
        color: $gray;
      }

      div {
        border: 1px solid $medium-gray;
        padding: 5px;
      }

      &_textarea {
        div {
          height: 300px;
          width: 400px;
          overflow-wrap: break-word;
        }
      }
    }
    &_input {
      margin: 0 0.625rem 0.938rem 0
    }
    &_item {
      display: block;
      padding: 0.375rem;
      border-bottom: 1px solid $medium-gray;

      span {
        padding: 0.125rem 0;
      }

      &:last-child {
        border: none;
      }
    }
  }

  &__files {
    width: 70%;
    border: 1px solid $medium-gray;
    padding: 0.375rem;
    margin: 0 0 1rem;
  }

  &__file {
    display: flex;
    justify-content: space-between;
    padding: 0.375rem;
    border-bottom: 1px solid $medium-gray;

    &:last-child {
      border: none;
    }

    img {
      cursor: pointer;
    }
  }

  &__form {
    max-width: 433px;
    margin-left: auto;
    &_approve {
      margin-bottom: 15px;
    }
    &_textarea {
      margin-top: 15px;
      position: relative;
      textarea {
        border: 1px solid black;
        resize: none;
        padding-left: 10px;
        padding-top: 5px;
      }
      &_close {
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
        padding: 5px;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
      }
      &_send {
        position: absolute;
        bottom: 15px;
        right: 10px;
        cursor: pointer;
        background: #f1e6e6;
        padding: 5px 15px;
        color: #000;
        border-radius: 15px;
      }
    }
  }
}

.notes {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  &__current,
  &__previous {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  &__current {
    width: 40rem;
  }
  &__title {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 700;
  }
  &__comment {
    width: 100%;
    min-height: 6.25rem;
  }
  &__buttons {
    display: flex;
    gap: 1.5rem;
    & > button {
      width: max-content;
    }
  }
}

.periods {
  width: 25.1875rem;
  background-color: #f8f8f8;
  border-radius: 0.625rem;
  padding: 1.25rem;
  padding-bottom: 3.375rem;
  position: relative;

  .pin {
    width: 1.5rem;
    height: 3.4375rem;
    position: absolute;
    right: 1.75rem;
    top: -1.4375rem;
  }
  .calendar {
    margin-top: 3.25rem;
  }
}
</style>
